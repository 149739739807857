export const TELTONIKA = [
    { label: 'Reconnect', command: 'track', icon: 'Track', isPopUp: false, types: [1, 3] },
    { label: 'Get Location', command: 'track', icon: 'Locations', isPopUp: false, types: [1, 2, 3] }
];

export const TELTONIKA_TST100 = [
    { label: 'Light On', command: 'lightOn', icon: 'LightOn', isPopUp: false, types: [1, 3] },
    { label: 'Light Off', command: 'lightOff', icon: 'LightOff', isPopUp: false, types: [1, 3] },
    { label: 'Alarm On', command: 'alarmOn', icon: 'AlarmOn', isPopUp: false, types: [1, 2, 3] },
    { label: 'Alarm Off', command: 'alarmOff', icon: 'AlarmOff', isPopUp: false, types: [1, 2, 3] },
    { label: 'Max Speed', command: 'setMaxSpeed', icon: 'SetMaxSpeed', isPopUp: true, types: [1, 3], key: 'maxSpeedLimit' }
];

export const TELTONIKA_TST100_FIT_RIDER = [
    { label: 'Light On', command: 'lightOn', icon: 'LightOn', isPopUp: false, types: [1, 3] },
    { label: 'Light Off', command: 'lightOff', icon: 'LightOff', isPopUp: false, types: [1, 3] },
    { label: 'Alarm On', command: 'alarmOn', icon: 'AlarmOn', isPopUp: false, types: [1, 2, 3] },
    { label: 'Alarm Off', command: 'alarmOff', icon: 'AlarmOff', isPopUp: false, types: [1, 2, 3] },
    { label: 'Max Speed', command: 'setMaxSpeed', icon: 'SetMaxSpeed', isPopUp: true, types: [1, 3], key: 'maxSpeedLimit' },
    { label: 'BT Lock Unlock', command: 'btLockUnlock', icon: 'CableLockUnlock', isPopUp: false, types: [1, 3] }
];

export const TELTONIKA_TFT100 = [
    { label: 'Boot Open', command: 'bootOpen', icon: 'BootOpen', isPopUp: false, types: [1, 3] },
    { label: 'Boot Close', command: 'bootClose', icon: 'BootClose', isPopUp: false, types: [1, 3] },
    { label: 'Reverse On', command: 'throttleOn', icon: 'BootOpen', isPopUp: false, types: [1, 3] },
    { label: 'Reverse Off', command: 'throttleOff', icon: 'BootClose', isPopUp: false, types: [1, 3] },
    { label: 'Reconnect', command: 'track', icon: 'Track', isPopUp: false, types: [1, 3] }
];

export const TELTONIKA_FMB920 = [
    { label: 'Boot Open', command: 'bootOpen', icon: 'BootOpen', isPopUp: false, types: [1, 3] },
    { label: 'Boot Close', command: 'bootClose', icon: 'BootClose', isPopUp: false, types: [1, 3] }
];

export const TELTONIKA_FMB130 = [
    { label: 'Reconnect', command: 'track', icon: 'Track', isPopUp: false, types: [1, 2, 3] },
    { label: 'Get Program', command: 'getProgram', icon: 'Getconfig', isPopUp: false, types: [1, 2, 3] },
    { label: 'Get Info', command: 'getInfo', icon: 'Getconfig', isPopUp: false, types: [1, 2, 3] },
    { label: 'Remove Simple Tacho', command: 'removeSimpleTacho', icon: 'Buzoff', isPopUp: false, types: [1, 2, 3] },
    { label: 'Add Simple Tacho', command: 'addSimpleTacho', icon: 'Buzon', isPopUp: false, types: [1, 2, 3] },
    { label: 'Turn On All Mode', command: 'turnOnAllMode', icon: 'BatteryLock', isPopUp: false, types: [1, 2, 3] },
    { label: 'Turn On Lv Mode', command: 'turnOnLvMode', icon: 'BatteryStatus', isPopUp: false, types: [1, 2, 3] },
    { label: 'Read DTC Fault Codes', command: 'readDtcFaultCodes', icon: 'Batunlock', isPopUp: false, types: [1, 2, 3] },
    { label: 'Open Trunk', command: 'openTrunk', icon: 'Listplay', isPopUp: false, types: [1, 2, 3] },
    { label: 'Turning Lights', command: 'turningLights', icon: 'LightOn', isPopUp: false, types: [1, 2, 3] },
    { label: 'Activate Horn', command: 'activateHorn', icon: 'SetPingInterval', isPopUp: false, types: [1, 2, 3] }
];

export const TELTONIKA_FMB140 = [
    { label: 'Set Total Engine Work Time', command: 'setTotalEngineWorkTime', icon: 'SetPingInterval', isPopUp: false, types: [1, 2, 3] },
    { label: 'Set Total Mileage', command: 'setTotalMileage', icon: 'SetPingInterval', isPopUp: false, types: [1, 2, 3] },
    { label: 'Reset Fuel Consumption', command: 'resetFuelConsumption', icon: 'SetPingInterval', isPopUp: false, types: [1, 2, 3] },
    { label: 'Set Total CNG Use', command: 'setTotalCngUse', icon: 'SetPingInterval', isPopUp: false, types: [1, 2, 3] },
    { label: 'Set Electric Engine Work Time', command: 'setElectricEngineWorkTime', icon: 'SetPingInterval', isPopUp: false, types: [1, 2, 3] },
    { label: 'Set Wheel Speed Sensor Impulses', command: 'setWheelSpeedSensorImpulses', icon: 'SetPingInterval', isPopUp: false, types: [1, 2, 3] },
    { label: 'Report Adapter Status', command: 'reportAdapterStatus', icon: 'UploadFileToScooter', isPopUp: false, types: [1, 2, 3] },
    { label: 'Reset Adapter', command: 'resetAdapter', icon: 'Track', isPopUp: false, types: [1, 2, 3] },
    { label: 'Connection Info', command: 'connectionInfo', icon: 'Getconfig', isPopUp: false, types: [1, 2, 3] },
];